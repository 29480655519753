import { Box, Paper, useMediaQuery } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { VictoryLegend, VictoryPie } from 'victory';
import { ColorScalePropType } from 'victory-core';
import { StyledHeading, StyledHeadingWrapper } from '../../styles';

interface Props {
  chartHeader: string;
  insuredLabel: string;
  insuredLabelValue: string;
  insuredData: number;
  insuredPercentage: number;
  insuredColour: string;
  uninsuredLabel: string;
  uninsuredLabelValue: string;
  uninsuredData: number;
  uninsuredPercentage: number;
}

export const InsuredPieChart = ({
  chartHeader,
  insuredLabel,
  insuredLabelValue,
  insuredData,
  insuredPercentage,
  insuredColour,
  uninsuredLabel,
  uninsuredLabelValue,
  uninsuredData,
  uninsuredPercentage,
}: Props) => {
  const matches = useMediaQuery('(min-width: 576px) and (max-width: 899px');

  return (
    <Paper sx={{ height: '100%' }}>
      <StyledHeadingWrapper>
        <StyledHeading>{chartHeader}</StyledHeading>
      </StyledHeadingWrapper>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          ['@media (min-width: 900px) and (max-width:1199px)']: {
            padding: '1rem 0',
          },
          ['@media (min-width: 576px) and (max-width:899px)']: {
            padding: '1rem 0 2rem 0',
            flexDirection: 'row',
          },
          ['@media (max-width: 425px)']: {
            padding: '1rem 0 1rem 0',
          },
          m: 2,
        }}>
        <Box>
          <VictoryPie
            colorScale={[insuredColour, 'hsl(0,0%,60%)']}
            animate={{ duration: 500 }}
            labelRadius={25}
            height={350}
            style={{
              labels: { fontSize: matches ? 24 : 20, fill: 'white' },
              data: {
                stroke: 'white',
                strokeWidth: 2,
              },
            }}
            data={[
              { x: insuredPercentage + '%', y: insuredData },
              { x: uninsuredPercentage + '%', y: uninsuredData },
            ]}
          />
        </Box>
        <Box>
          <VictoryLegend
            colorScale={[insuredColour, 'hsl(0,0%,60%)']}
            orientation="vertical"
            itemsPerRow={2}
            gutter={40}
            height={matches ? 150 : 100}
            style={{ labels: { fontSize: matches ? 24 : 20 } }}
            y={0}
            data={[
              { name: insuredLabel + ': ' + insuredLabelValue },
              { name: uninsuredLabel + ': ' + uninsuredLabelValue },
            ]}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default InsuredPieChart;
