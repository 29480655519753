import { GetStaticProps } from 'next';
import { homeConfig } from '../features/home/home.config';
import { HomePage } from '../features/home/home.page';
import { ssrTranslations } from '../hooks/ssr-translations';

export default function HomeRoute() {
  return <HomePage />;
}

export const getStaticProps: GetStaticProps = async (ctx) => {
  const tProps = await ssrTranslations(ctx, homeConfig.i18nNamespaces);

  return {
    props: {
      ...tProps,
    },
  };
};
