import { styled } from '@b7hio/007';
import { colorsHsla } from '@b7hio/007/theme/colors';

export const StyledHeadingWrapper = styled('div', {
  padding: '$2 $3',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '$2',
  alignItems: 'flex-start',
  position: 'relative',
  '& > svg': {
    background: '$brand3',
    padding: '$3',
    fontSize: '$2',
    color: '$primary',
    borderRadius: '$3',
  },
  variants: {
    bottomBorder: {
      true: {
        '&::after': {
          content: '',
          position: 'absolute',
          width: 'calc(100% - 31px)',
          height: '1px',
          background: '$gray6',
          bottom: '0',
          right: '$3',
        },
      },
      false: {},
    },
    noIcon: {
      true: {
        '@sm': {
          justifyContent: 'center',
          '& > svg': {
            display: 'none',
          },
          '& > p': {
            paddingLeft: '0',
          },
        },
      },
      false: {},
    },
  },
  defaultVariants: {
    bottomBorder: 'true',
  },
});

export const StyledHeading = styled('p', {
  color: colorsHsla('ultramarineBlue'),
  // paddingLeft: '$3',
  fontSize: '$2',
  fontWeight: '$3',
  marginTop: '$2',
  marginBottom: '$2',
});

export const StyledSubHeading = styled('p', {
  color: '$gray14',
  // paddingLeft: '$3',
  fontSize: '$1',
  marginTop: '-$3',
  marginBottom: '$2',
});
