import {
  useGetBankStatistics,
  useGetInsuredBalanceStatistics,
  useListActiveAndPendingProducts,
} from '@b7hio/api-lib/gen/bank-portal-api';
import { LoadingSpinner } from '@b7hio/core-lib';
import { Overview, PageHeader } from '@b7hio/ops-ui-lib';
import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';
import type React from 'react';
import { Bar } from 'react-chartjs-2';
import { useUser } from '../../hooks/useUser';
import InsuredPieChart from './components/InsuredPieChart';
import { StyledHeading, StyledHeadingWrapper } from './styles';

export const HomePage = () => {
  const { t } = useTranslation(['shell', 'common', 'home']);
  const matches = useMediaQuery('(min-width: 576px) and (max-width: 899px');

  const formatter = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'GBP',
  });

  const productsData = useListActiveAndPendingProducts({
    currentState: 'ACTIVE',
  }).data;

  const { data, isLoading, error } = useGetBankStatistics();
  const [, partnerUid] = useUser();

  const {
    data: insuredData,
    isLoading: insuredIsLoading,
    error: insuredError,
  } = useGetInsuredBalanceStatistics();

  if (isLoading || insuredIsLoading) {
    return <LoadingSpinner />;
  }

  if (error || !data || insuredError) {
    return (
      <>
        There was an unfortunate error in which this data could not be located
        or returned.
      </>
    );
  }

  const { depositsByProducts, dailyVolume } = data;

  // Total Deposits by product
  const totalDeposits = depositsByProducts
    .map((v) => v.deposit)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  // Total Products
  const totalProducts = productsData?.totalSize ?? 0;

  // Calculate and display top products for Victory Chart
  const calcTopProducts = (showNames?: boolean) => {
    return depositsByProducts
      .map((v) => {
        const percentage = ((v.deposit / totalDeposits) * 100).toFixed();
        return showNames
          ? {
              name: `${v.name} ${percentage}%`,
            }
          : {
              x: `${percentage}%`,
              y: v.deposit / totalDeposits,
            };
      })
      .slice(0, 5);
  };

  // Charts
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const dailyVolumeInfo = dailyVolume.sort(
    (a, b) => new Date(a.day).getTime() - new Date(b.day).getTime()
  );

  const dailyVolumeData = {
    labels: dailyVolumeInfo.map((v) => v.day).sort(),
    datasets: [
      {
        label: 'Net',
        data: dailyVolumeInfo.map((v) => v.net),
        // borderColor: 'red',
        backgroundColor: dailyVolumeInfo.map((v) =>
          v.net < 0 ? '#FA7F7F' : '#A3EDB4'
        ),
      },
    ],
  };

  const valueToCurrency = (value: string) => {
    return parseInt(value) >= 0 ? `£${value}` : `-£${value.split('-')[1]}`;
  };

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleAlign: 'center',
        bodyAlign: 'center',
        displayColors: false,
        bodyFont: {
          family: "'Roboto', sans-serif",
          size: 20,
        },
        titleColor: '#757575',
        titleFont: {
          family: "'Roboto', sans-serif",
          size: 13,
          weight: 400,
        },
        backgroundColor: 'white',
        borderColor: '#DDD',
        borderWidth: 1,
        padding: 10,
        callbacks: {
          label: function (context: any) {
            return valueToCurrency(context.formattedValue);
          },
          labelTextColor: function (context: any) {
            return context.formattedValue.indexOf('-') > -1
              ? '#D12654'
              : '#48A36F';
          },
        },
      },
    },
    scales: {
      yAxis: {
        position: 'right',
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <NextSeo
        title={t('home:page.title')}
        description="See https://github.com/b7hio/exchange-ui"
      />
      <PageHeader title={t('home:page.title')} />
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Paper sx={{ height: '100%' }}>
              <Overview
                totalDeposits={totalDeposits}
                totalProducts={totalProducts}
                portal="banks"
              />
            </Paper>
          </Grid>
          {insuredData != null && (
            <Grid item xs={12} md={6} lg={4}>
              <InsuredPieChart
                chartHeader={t('home:insuredDeposits.header')}
                insuredLabel={t('home:insuredDeposits.legend.insuredDeposits')}
                insuredLabelValue={formatter.format(insuredData.insuredBalance)}
                insuredData={insuredData.insuredBalance}
                insuredPercentage={insuredData.insuredBalancePercentage}
                insuredColour={'hsla(228, 100%, 69%, 1)'}
                uninsuredLabel={t(
                  'home:insuredDeposits.legend.uninsuredDeposits'
                )}
                uninsuredLabelValue={formatter.format(
                  insuredData.uninsuredBalance
                )}
                uninsuredData={insuredData.uninsuredBalance}
                uninsuredPercentage={insuredData.uninsuredBalancePercentage}
              />
            </Grid>
          )}
          {insuredData != null && (
            <Grid item xs={12} md={6} lg={4}>
              <InsuredPieChart
                chartHeader={t('home:insuredClients.header')}
                insuredLabel={t('home:insuredClients.legend.insuredClients')}
                insuredLabelValue={insuredData.insuredCustomers.toString()}
                insuredData={insuredData.insuredCustomers}
                insuredPercentage={insuredData.insuredCustomersPercentage}
                insuredColour={'hsla(321, 45%, 57%, 1)'}
                uninsuredLabel={t(
                  'home:insuredClients.legend.uninsuredClients'
                )}
                uninsuredLabelValue={insuredData.uninsuredCustomers.toString()}
                uninsuredData={insuredData.uninsuredCustomers}
                uninsuredPercentage={insuredData.uninsuredCustomersPercentage}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper>
              <StyledHeadingWrapper bottomBorder={false}>
                <StyledHeading>{t('home:dailyVolume.header')}</StyledHeading>
              </StyledHeadingWrapper>
              <Box sx={{ padding: '0 1rem 1rem 1rem' }}>
                <Bar options={options} data={dailyVolumeData} height={75} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
